import { QueryStatus } from '@tanstack/react-query';
import {
  CommunityQuestTag,
  QuestDetailsType,
  sortQuestsByTag,
} from '@xborglabs/ui-shared';
import Skeleton from 'react-loading-skeleton';
import { P, match } from 'ts-pattern';

import QuestCategoryContainer from '@/modules/quests/components/QuestCategoryContainer/QuestCategoryContainer';
import { QuestsFilters } from '@/modules/quests/components/QuestsFilters/QuestsFilters';
import { SingleQuest } from '@/modules/quests/components/SingleQuest/SingleQuest';
import {
  ListError,
  questErrorImage,
} from '@/modules/shared/components/Template/Errors';
import classes from '@/styles/animations/shimmer.module.scss';

export type QuestsCategorizedListProps = {
  list?: QuestDetailsType[];
  status: QueryStatus;
  filters?: CommunityQuestTag[];
  filtersStatus: QueryStatus;
  disabled?: boolean;
  rejectedMessage: string;
  emptyMessage: string;
  onTagChange: (list: string[]) => void;
  onReload?: () => void;
};

export const QuestsCategorizedList = ({
  list,
  status,
  filters,
  filtersStatus,
  rejectedMessage,
  emptyMessage,
  onTagChange,
  onReload,
}: QuestsCategorizedListProps) => {
  const sortedList = sortQuestsByTag(list);
  return (
    <div className="quests-categorized-list">
      {match([status, filters])
        .with(
          [P.union('pending', 'success'), P.not(P.nullish)],
          ([, filters]) => (
            <QuestsFilters
              onChange={onTagChange}
              filters={filters}
              status={filtersStatus}
            />
          ),
        )
        .otherwise(() => null)}

      {match([status, list?.length ?? 0])
        .with(['success', P.when((l) => l > 0)], () =>
          Object.values(sortedList).map((item, index) => (
            <QuestCategoryContainer
              filters={filters}
              key={index}
              label={item.label}
              list={item.list}
              slug={item.slug}
            />
          )),
        )
        .with(['success', 0], () => (
          <ListError text={emptyMessage} image={questErrorImage} />
        ))
        .with(['pending', P.select()], () => <QuestTagsLoading />)
        .with(['error', P.select()], () => (
          <ListError
            text={rejectedMessage}
            onReload={onReload}
            image={questErrorImage}
          />
        ))
        .otherwise(() => (
          <></>
        ))}
    </div>
  );
};

function QuestTagsLoading() {
  return (
    <div className="category-container">
      <div className="flex">
        <div className="category-name lexend-body-xs1">
          <Skeleton />
        </div>
      </div>
      <div className="category-quests flex flex-wrap">
        <SingleQuest className={classes['animate-shimmer']} />
        <SingleQuest className={classes['animate-shimmer']} />
        <SingleQuest className={classes['animate-shimmer']} />
        <SingleQuest className={classes['animate-shimmer']} />
        <SingleQuest className={classes['animate-shimmer']} />
        <SingleQuest className={classes['animate-shimmer']} />
        <SingleQuest className={classes['animate-shimmer']} />
      </div>
    </div>
  );
}
